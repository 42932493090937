.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}





.blue-text {
  color: #008000; /* This is a light bright blue color */
  -webkit-text-stroke: 0.75px red; /* Add this line for the bright red stroke */
  text-stroke: 0.75px red; /* Add this line for the bright red stroke */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Black drop shadow */
}
a {
  color: #00008B; /* Dark blue link color to match the text */
  text-decoration: none; /* Removes underline from links */
  font-weight: bold; /* Makes link text bold */
}

a:hover {
  text-decoration: underline; /* Adds underline on hover for better user interaction */
}

.large-text {
  display: block; /* Makes the element a block to force it onto a new line */
  font-size: 2em; /* Increases the font size */
  margin-top: 20px; /* Adds space above the text */
  color: #00008B; /* Ensures the color is dark blue */
  font-weight: bold; /* Makes the text bold */
}



.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.embedgpt-logo{
  cursor:pointer;
  position: fixed;
  width: 3rem;
  height: 3rem;
  bottom: 32px;
  right: 32px;
  padding: 4px;
  z-index: 999999;
  opacity: 1; 
  border-radius: 50%;
  box-shadow: 0 0 8px 2px rgba(22, 58, 71, 0.7);
  transform: scale(1);

}
.embedgpt-logo:hover{
  scale: 1.1;
}
.embedgpt-iframe{
  display:none;
  position: fixed;
  width: 396px;
  height: 75vh;
  bottom: 94px;
  right: 32px;
  pointer-events: none;
  z-index:99999 ;
  opacity: 0;
  transform: translateX(100px);
  transition: .6s ease opacity,.6s ease transform;
  border-radius: 22px;
  box-shadow: inset 0px 1px 1px 0px rgba(255, 255, 255, 0.1), 0px 10px 100px -20px rgba(50, 50, 93, 0.25), 0px 20px 60px -30px rgba(0, 0, 0, 0.3);
}
@keyframes pulse-black {
  0% {
      transform: scale(1);
      box-shadow: 0 0 8px 4px rgba(22, 58, 71, 0.7);
  }
  
  70% { 
      transform: scale(0.95);
      box-shadow: 0 0 8px 8px rgba(22, 58, 71, 0.2);
  }
  
  100% {
      transform: scale(1);
      box-shadow: 0 0 8px 4px rgba(22, 58, 71, 0.7);
  }


}
@media screen and (max-width:1000px){
  .embedgpt-logo{
      width: 3rem;
      height: 3rem;
      bottom: 12px;
      right: 12px;
  }
  .embedgpt-iframe{
      width: 90%;
      height: 90%;
      bottom: 50px;
      right: 16px;
  }
}

.embedgpt-welcome-message{
  cursor:pointer;
  position: fixed;
  bottom: 110px;
  right: 32px;
  padding: 4px;
  z-index: 999999;
  opacity: 1; 
  border-radius: 10px;
  box-shadow: 0 0 8px 2px rgba(216, 216, 216, 0.7);
  background: white; 
}
/* General Styling */
/* General Styling */
/* General Styling */
body {
  font-family: 'Segoe UI', 'Arial', sans-serif; /* Modern and professional font */
  margin: 0;
  padding: 0;
  background-color: #f9f9f9;
  color: #333;
}

/* Center Container Styling */
.center-container {
  text-align: center;
  padding: 30px;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  max-width: 900px; /* Slightly wider for a cleaner look */
  margin: 40px auto;
  width: 90%;
}

/* Header and Title Styling */
.title-text {
  font-size: 3rem; /* Larger and more prominent */
  color: #1e3c72;
  font-weight: 800;
  margin-bottom: 20px;
}

.brand-name {
  color: #2a5298;
}

.description-text {
  font-size: 1.25rem; /* Larger for readability */
  color: #555;
  margin-bottom: 30px;
  line-height: 1.8; /* Better spacing for readability */
  font-weight: 400;
}

/* Section Titles */
.section-title {
  font-size: 2rem; /* Larger section titles */
  color: #333;
  font-weight: 600;
  margin-bottom: 20px;
}

/* Brand List */
/* Brand List */
/* Brand List */
.brand-list {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: left;
  line-height: 2; /* Keeps readability clear */
}

.brand-list li {
  margin: 20px 0; /* Increased spacing between list items */
}

.brand-link {
  font-size: 1.5rem; /* Larger font size for the brand links */
  font-weight: 700; /* Bolder text for emphasis */
  color: #1e3c72; /* Professional color */
  text-decoration: none;
  transition: color 0.3s ease;
}

.brand-link:hover {
  color: #2a5298; /* Subtle color change on hover */
  text-decoration: underline; /* Adds underline on hover for interactivity */
}


/* Live Example Section */
.live-example-container {
  text-align: left;
  background: #ffffff;
  padding: 30px;
  margin-top: 50px;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}

.service-list {
  list-style: disc;
  padding-left: 30px;
  margin: 20px 0;
}

.service-list li {
  font-size: 1.25rem; /* Larger font for services */
  margin-bottom: 10px;
  color: #555;
}

/* Login Section */
.login-container {
  margin-top: 40px;
}

.login-prompt {
  font-size: 1.25rem; /* Larger font */
  color: #666;
  margin-bottom: 15px;
}

button {
  font-size: 1.2rem; /* Larger button text */
  color: #ffffff;
  background-color: #1e3c72;
  border: none;
  padding: 15px 30px; /* Larger button for better usability */
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

button:hover {
  background-color: #2a5298;
  transform: translateY(-2px);
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .center-container {
    padding: 20px;
  }

  .title-text {
    font-size: 2.5rem;
  }

  .description-text {
    font-size: 1.1rem;
  }

  .section-title {
    font-size: 1.8rem;
  }

  .brand-list li {
    font-size: 1.1rem;
  }

  .service-list li {
    font-size: 1.1rem;
  }

  button {
    padding: 12px 24px;
    font-size: 1rem;
  }
}



/* Footer Styling */
.footer {
  position: relative;
  background-color: #f8f9fa; /* Light background for contrast */
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #ddd;
}

.footer-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* HIPAA Logo Styling */
.hippa-logo-container {
  position: absolute;
  bottom: 10px;
  left: 10px; /* Aligns the logo to the bottom-left of the footer */
  width: 125px;
  height: 125px;
}

.hippa-logo {
  width: 100%;
  height: 100%;
  object-fit: contain; /* Ensures the image fits properly */
}
